/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';

import { useAppDetails } from '@/hooks/useAppDetails';
import { ComponentDisplayItems, DisplayItems } from '@/components/items';
import { DetailsPageLoading } from '@/components/loading';
import { ServicesGraph } from '@/components/services-graph';

export const ArchitecturePage = () => {
  const { messages } = useLanguage();
  const langData = messages?.General;
  const appLangData = messages?.ApplicationDetailPage;

  const {
    data,
    loading,
    addedComponent,
    setAddedComponent,
    appData,
    fetchAppDetails,
    setAppData,
  } = useAppDetails();

  if (loading || !data) {
    return <DetailsPageLoading />;
  }

  return (
    <>
      <ServicesGraph
        title={appLangData?.connected_application_components as string}
        data={data}
        loading={loading}
        onRefresh={fetchAppDetails}
        appData={appData}
        addedComponent={addedComponent}
        setAddedComponent={setAddedComponent}
        setAppData={setAppData}
        displayAddCompBtn={true}
      />

      {loading ? (
        <div className="text-white">{langData?.loading}</div>
      ) : (
        <div className="flex gap-x-[30.83px] pb-5">
          <DisplayItems data={data} />
          <ComponentDisplayItems
            data={data}
            onRefresh={fetchAppDetails}
            appDetails={appData}
            addedComponent={addedComponent}
            setAddedComponent={setAddedComponent}
            setAppDetails={setAppData}
          />
        </div>
      )}
    </>
  );
};
