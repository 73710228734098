/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/

import React, { Dispatch, SetStateAction } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { IPrompt } from 'types/prompt';

import { CustomButton } from '../common';

export const PromptPagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
  promptList,
}: {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  promptList: IPrompt[] | object;
}) => {
  const { messages } = useLanguage();

  const isListEmpty =
    (Array.isArray(promptList) && promptList.length === 0) ||
    (typeof promptList === 'object' &&
      promptList !== null &&
      Object.keys(promptList).length === 0);

  const isPreviousDisabled = currentPage === 1 || isListEmpty;
  const isNextDisabled = currentPage === totalPages || isListEmpty;

  return (
    <div className="mt-4 flex items-center justify-end gap-2">
      <CustomButton
        customSize="regular"
        customVariant="secondary"
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        disabled={isPreviousDisabled}
      >
        {messages?.Buttons.previous}
      </CustomButton>

      <CustomButton
        customSize="regular"
        customVariant="secondary"
        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={isNextDisabled}
      >
        {messages?.Buttons.next}
      </CustomButton>
    </div>
  );
};
