/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { useState } from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import { request } from '@/services/request';
import { toast } from 'sonner';
import { AppGraph, Component } from 'types/api-data';
import { IAppData, IComponentData } from 'types/new-app-d';

import { CustomButton } from '../common';
import { LayeredDigraphLayout } from '../graph';
import { System } from '../icons';
import { AssignableComp } from '../new-app-page/components/AssignableComp';
import { NotFoundMessage } from '../not-found';
import { FullScreenSpinner } from '../spinner/full-screen-spinner';

export const ServicesGraph = ({
  data,
  loading,
  onRefresh,
  appData,
  addedComponent,
  setAddedComponent,
  setAppData,
  title,
  displayAddCompBtn,
}: {
  data?: AppGraph;
  loading?: boolean;
  onRefresh?: () => void;
  appData?: IAppData;
  addedComponent?: IComponentData[];
  setAddedComponent?: React.Dispatch<React.SetStateAction<IComponentData[]>>;
  setAppData?: (value: IAppData) => void;
  title: string;
  displayAddCompBtn?: boolean;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { messages } = useLanguage();
  const langData = messages?.ApplicationDetailPage;

  const handleAddComponent = async (selectedComponent: IComponentData[]) => {
    try {
      setIsLoading(true);

      const body = {
        ...appData,
        components: [...(appData?.components || []), ...selectedComponent],
      };

      const response = await request.put(
        { endpoint: `apps/${data?.name}` },
        body
      );

      setAppData?.(response[0]);
      toast.success(langData?.component_added_successfully);
      onRefresh?.();
      return response[0];
    } catch (error) {
      toast.error(langData?.error_in_adding_component);
      console.log('error', error);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const dataTransform = (data: AppGraph) => {
    return (
      data.components?.filter((item: Component) => item.domain === 'logical') ||
      []
    );
  };

  const transformedData = data ? dataTransform(data) : [];
  const isComponentThere = transformedData.length > 0;

  return (
    <div className="py-8">
      {isLoading && <FullScreenSpinner text="Adding Component" />}
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-2">
          <h2 className="text-h2 text-body-primary">{title}</h2>
          {System.InfoIcon('size-4 text-icon-fill')}
        </div>
        {displayAddCompBtn && (
          <CustomButton
            customVariant="secondary"
            customSize="regular"
            onClick={() => setDialogOpen(true)}
          >
            {langData?.add_components}
          </CustomButton>
        )}
      </div>
      <div className="flex h-[420px] w-full flex-col items-center justify-center rounded-md border border-line-secondary bg-canvas-background-primary text-center">
        {loading ? (
          <>
            <Image
              src="/logos/mono/medium.svg"
              width={100}
              height={100}
              alt="Logo"
            />
            <div className="space-y-4">
              <p className="text-body text-body-primary">
                {langData?.okahu_is_scanning_your_application_services}
              </p>
              <p className="text-body text-body-primary">
                {langData?.we_will_notify_you_when_the_process}
              </p>
            </div>
          </>
        ) : isComponentThere ? (
          <LayeredDigraphLayout data={data} />
        ) : (
          <div className="flex flex-col items-center gap-y-3">
            <NotFoundMessage
              message={langData?.you_dont_have_any_components_added}
              outerDivClassName="my-0"
            />
            {displayAddCompBtn && (
              <CustomButton
                customVariant="secondary"
                customSize="regular"
                type="button"
                onClick={() => setDialogOpen(true)}
              >
                {langData?.add_components}
              </CustomButton>
            )}
          </div>
        )}
      </div>
      {dialogOpen && (
        <AssignableComp
          open={dialogOpen}
          setOpen={setDialogOpen}
          addedComponent={addedComponent!}
          setAddedComponent={setAddedComponent!}
          addComponentFromDetail={handleAddComponent}
        />
      )}
    </div>
  );
};
