/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { usePathname, useRouter } from 'next/navigation';
import { useKPIContext } from '@/providers/KpiProvider';
import { useLanguage } from '@/providers/LanguageProvider';
import { RefreshCcwIcon } from 'lucide-react';

import { DateRangePicker } from '../display-page/prompts/date-range-picker/date-range';
import { Skeleton } from '../ui/skeleton';
import useKPI from './useKPI';
import { WorkflowDetails } from './workflow-details';

export const KPIItems = ({
  appName,
  workflowName,
  sla,
}: {
  appName?: string;
  workflowName?: string;
  sla?: string;
}) => {
  const { messages } = useLanguage();
  const router = useRouter();
  const pathname = usePathname();
  const isComponentsPage = pathname.includes('/components');
  const componentData = messages?.ComponentsPage;
  const { KPIData, loading, handleDateRangeApply } = useKPI(
    appName,
    workflowName
  );
  const { refreshKPIData } = useKPIContext();

  const handleTraceClick = () => {
    const currentPath = window.location.pathname;
    const currentParams = new URLSearchParams(window.location.search);

    const newPath = `${currentPath}/traces`;

    if (
      currentParams.get('days') === 'all' ||
      currentParams.get('days') === '7'
    ) {
      currentParams.delete('start');
      currentParams.delete('end');
    }

    const newUrl = `${newPath}?${currentParams.toString()}`;

    router.push(newUrl);
  };

  return (
    <div>
      <div className="mt-8 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h2 className="text-h2 text-body-primary">
            {isComponentsPage
              ? `${componentData?.component_insights}`
              : `${messages?.ApplicationDetailPage?.application_insights}`}
          </h2>

          <button onClick={refreshKPIData}>
            <RefreshCcwIcon className="size-4 text-[#257C9D]" />
          </button>
        </div>
      </div>

      <div className="pt-4">
        <div className="flex">
          <div className="mr-10 pb-4">
            <DateRangePicker
              onUpdate={handleDateRangeApply}
              classNames={{
                rangeTriggerClassName:
                  'border-none py-0 focus:ring-0 focus:ring-offset-0',
                triggerIconClassName: 'gap-[10px] items-end',
                rangeClassName: 'text-link-active text-[14px]',
                labelClassName: 'mb-[6px]',
              }}
              fromInsight
            />
          </div>

          {loading ? (
            <div className="space-y-[14px]">
              <Skeleton className="animate-pulse-custom h-2.5 w-[664px] rounded-full bg-[#8C9EA1]" />
              <Skeleton className="animate-pulse-custom h-2.5 w-[828px] rounded-full bg-[#8C9EA1]" />
              <Skeleton className="animate-pulse-custom h-2.5 w-[734px] rounded-full bg-[#8C9EA1]" />
            </div>
          ) : (
            <div className="flex gap-10">
              <div className="border-l border-line-secondary pl-[9px]">
                <p className="text-form-input-text text-body-primary">
                  {messages?.General?.traces}
                </p>
                <p
                  className="cursor-pointer pt-[9px] text-kpi-value text-link-active"
                  onClick={handleTraceClick}
                >
                  {KPIData?.total_request_count ?? KPIData?.request_count ?? 0}{' '}
                </p>
              </div>
              {/* <div className="border-l border-line-secondary pl-[9px]">
              <p className="text-body text-body-primary">Avg. Duration</p>
              <p className="pt-[9px] text-kpi-value text-link-active">3.8s</p>
            </div> */}
              <div className="border-l border-line-secondary pl-[9px]">
                <p className="text-form-input-text text-body-primary">
                  {messages?.ApplicationDetailPage?.status}
                </p>
                <p className="pt-[9px] text-kpi-value text-link-active">
                  {messages?.General?.active}
                </p>
              </div>
              <WorkflowDetails data={KPIData} sla={sla} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
