/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useState } from 'react';
import { usePathname } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { constructPromptCriteriaFilterString } from '@/utils/constructPromptCriteriaFilterString';
import { convertToUTCDate } from '@/utils/convertToUTCDate';
import { FilterCriteriaGroup } from 'types/prompt';

import { removeFilterDuplicates } from '@/lib/helper';
import { usePrompts } from '@/hooks/usePrompts';
import { Skeleton } from '@/components/ui/skeleton';
import { MultiSelect } from '@/components/combobox';
import { CustomButton } from '@/components/common';
import { System } from '@/components/icons';
import { PromptsListing } from '@/components/prompts-listing';

import { AddPromptCriteriaFilters } from './add-criteria-filter/add-promt-criteria-filters';
import { DateRange } from './date-range-picker/calendar-helper';
import { DateRangePicker } from './date-range-picker/date-range';

const PromptsPage = () => {
  const [appliedFilters, setAppliedFilters] = useState<FilterCriteriaGroup[]>(
    []
  );
  const {
    todaysDate,
    handleFormSubmit,
    appDataLoading,
    transformedWorkflows,
    transformedModels,
    transformedServices,
    transformedProviders,
  } = usePrompts();

  const [multiSelect, setMultiselect] = useState({
    workflow_names: '',
    model_names: '',
    services: '',
    providers: '',
  });

  const [dateRange, setDateRange] = useState<{
    start_time: null | string;
    end_time: null | string;
  }>({
    start_time: '',
    end_time: '',
  });

  const { messages } = useLanguage();
  const pathname = usePathname();
  const isComponentPath = pathname.includes('/components/');

  const handleFilterChange = (newFilters: FilterCriteriaGroup[]) =>
    setAppliedFilters(newFilters);

  const handleSearch = () => {
    const filterString = constructPromptCriteriaFilterString(appliedFilters);
    handleFormSubmit({
      filter: filterString,
      ...multiSelect,
      ...dateRange,
    });
  };

  const handleMultiSelect = (key: string, valueSelected: string[]) => {
    setMultiselect((prev) => ({ ...prev, [key]: valueSelected.join(',') }));
  };

  const handleDateRangeChange = (range: DateRange) => {
    setDateRange((prev) => {
      const newRange = {
        ...prev,
        start_time: convertToUTCDate(
          range.from ? new Date(range.from) : null,
          false
        ),
        end_time: convertToUTCDate(range.to ? new Date(range.to) : null, true),
      };
      return newRange;
    });
  };

  return (
    <div className="mb-5 mt-6 flex min-h-[73vh] rounded-lg border border-line-secondary bg-card-00">
      <div className="h-[973px] w-[280px] overflow-y-auto">
        <div className="space-y-6 p-5">
          <div>
            <p className="mb-2 flex items-center gap-1">
              <span className="text-form-input-text text-body-primary">
                {messages?.PromptFilters?.date_range?.time_range}
              </span>
              <span>{System.InfoIcon('size-3 text-[#257C9D]')}</span>
            </p>
            {appDataLoading ? (
              <Skeleton className="animate-pulse-custom h-[66px] rounded-lg bg-[#8C9EA1]" />
            ) : (
              <DateRangePicker
                onRangeChange={handleDateRangeChange}
                todaysDate={todaysDate}
              />
            )}
          </div>

          <div>
            <p className="mb-2 flex items-center gap-1">
              <span className="text-form-input-text text-body-primary">
                {messages?.General?.prompts}
              </span>
              <span>{System.InfoIcon('size-3 text-[#257C9D]')}</span>
            </p>
            {appDataLoading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <AddPromptCriteriaFilters onFilterChange={handleFilterChange} />
            )}
          </div>

          {!isComponentPath && (
            <div className="space-y-2">
              <p className="text-form-input-text text-body-primary">
                {messages?.PromptFilters.multi_select.workflow}
              </p>
              {appDataLoading ? (
                <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
              ) : (
                <MultiSelect
                  options={transformedWorkflows}
                  onValueChange={(valueSelected) =>
                    handleMultiSelect('workflow_names', valueSelected)
                  }
                  placeholder={
                    messages?.PromptFilters.multi_select?.select_workflow
                  }
                  variant="inverted"
                  maxCount={2}
                  loading={appDataLoading}
                  maxOptions={5}
                />
              )}
            </div>
          )}

          <div className="space-y-2">
            <p className="text-form-input-text text-body-primary">
              {messages?.General?.model}
            </p>
            {appDataLoading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <MultiSelect
                options={removeFilterDuplicates(transformedModels)}
                onValueChange={(valueSelected) =>
                  handleMultiSelect('model_names', valueSelected)
                }
                placeholder={messages?.PromptFilters.multi_select.select_model}
                variant="inverted"
                maxCount={2}
                loading={appDataLoading}
                maxOptions={5}
              />
            )}
          </div>

          <div className="space-y-2">
            <p className="text-form-input-text text-body-primary">
              {messages?.General?.service}
            </p>
            {appDataLoading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <MultiSelect
                options={transformedServices}
                onValueChange={(valueSelected) =>
                  handleMultiSelect('services', valueSelected)
                }
                placeholder={
                  messages?.PromptFilters.multi_select.select_service
                }
                variant="inverted"
                maxCount={2}
                loading={appDataLoading}
                maxOptions={5}
              />
            )}
          </div>

          <div className="space-y-2">
            <p className="text-form-input-text text-body-primary">
              {messages?.General?.provider}
            </p>
            {appDataLoading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <MultiSelect
                options={transformedProviders}
                onValueChange={(valueSelected) =>
                  handleMultiSelect('providers', valueSelected)
                }
                placeholder={
                  messages?.PromptFilters.multi_select.select_provider
                }
                variant="inverted"
                maxCount={2}
                loading={appDataLoading}
                maxOptions={5}
              />
            )}
          </div>
          <CustomButton
            customVariant="primary"
            customSize="large"
            onClick={handleSearch}
            className="w-full"
            disabled={appDataLoading}
          >
            {messages?.General?.search}
          </CustomButton>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto border-l border-line-secondary">
        <PromptsListing />
      </div>
    </div>
  );
};

export { PromptsPage };
