/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/
import { Dispatch, SetStateAction, useState } from 'react';
import { IPrompt } from 'types/prompt';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';

import { CustomBadge } from '../common';
import { Dialog, DialogTrigger } from '../ui/dialog';
import { PromptCard } from './prompt-card';
import { PromptDetailsModal } from './prompt-detail-modal';

const accordionTriggerHeight = 52;
const promptListSectionHeight = 814;
const gapBetweenAccordion = 8;
const endPadding = 25;

export const PromptAccordion = ({
  accordionValue,
  setAccordionValue,
  promptList,
  selectedPrompt,
  setSelectedPrompt,
}: {
  accordionValue: string;
  setAccordionValue: Dispatch<SetStateAction<string>>;
  promptList: any;
  selectedPrompt: IPrompt[];
  setSelectedPrompt: Dispatch<SetStateAction<IPrompt[]>>;
}) => {
  const [position, setPosition] = useState<number>(0);

  const numberOfAccordions = Object.keys(promptList).length;

  const totalHeaderHeight =
    numberOfAccordions * accordionTriggerHeight +
    numberOfAccordions * gapBetweenAccordion +
    endPadding;
  const remainingHeight = promptListSectionHeight - totalHeaderHeight;

  const handleNext = () => {
    setPosition((prev) => prev + 1);
  };
  const handlePrev = () => {
    setPosition((prev) => prev - 1);
  };

  return (
    <div className="flex-grow">
      <Accordion
        type="single"
        collapsible
        className="flex w-full flex-col gap-2"
        value={accordionValue}
        onValueChange={setAccordionValue}
      >
        {Object.keys(promptList).map((groupName: string, index) => (
          <AccordionItem
            key={groupName}
            value={`item-${index}`}
            className="rounded-sm border border-[#40565A]"
          >
            <AccordionTrigger
              className={`bg-line-secondary px-3 hover:no-underline ${index === Number(accordionValue.split('-')[1]) ? 'rounded-t-sm' : 'rounded-sm'}`}
            >
              <div className="flex items-center gap-1.5">
                <p>{groupName}</p>
                <CustomBadge customSize="regular">
                  {promptList[groupName].length}
                </CustomBadge>
              </div>
            </AccordionTrigger>
            <AccordionContent
              style={{
                maxHeight:
                  index === Number(accordionValue.split('-')[1])
                    ? remainingHeight
                    : 'unset',
                overflow:
                  index === Number(accordionValue.split('-')[1])
                    ? 'auto'
                    : 'unset',
              }}
              className={`flex flex-col gap-1 p-1`}
            >
              {promptList[groupName].map((prompt: IPrompt, id: number) => (
                <Dialog key={prompt.trace_id}>
                  <DialogTrigger asChild onClick={() => setPosition(id)}>
                    <div>
                      <PromptCard
                        selectedPrompt={selectedPrompt}
                        setSelectedPrompt={setSelectedPrompt}
                        item={prompt}
                      />
                    </div>
                  </DialogTrigger>
                  <PromptDetailsModal
                    position={position}
                    data={promptList[groupName]}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                  />
                </Dialog>
              ))}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
