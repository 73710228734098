/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { NavItem } from 'types/nav';

import { cn } from '@/lib/utils';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import Medium from '../../../public/logos/full-color-reversed/medium.svg';
import { NavIcons, System } from '../icons';

export const SideNav = () => {
  const pathname = usePathname();
  const { locale, messages } = useLanguage();

  const sidebar: NavItem[] = [
    {
      id: 0,
      title: messages?.General?.applications,
      href: '/apps',
      icon: NavIcons.AppIcon(),
    },
    {
      id: 1,
      title: messages?.General?.services,
      href: '/services',
      icon: NavIcons.ServicesIcon(),
    },
    {
      id: 2,
      title: messages?.Sidebar?.providers,
      href: '/providers',
      icon: NavIcons.ProvidersIcon(),
    },
    {
      id: 3,
      title: messages?.General?.settings,
      href: '/settings',
      icon: System.SettingsIcon(),
    },
    {
      id: 4,
      title: messages?.Sidebar?.documentation,
      href: 'https://docs.okahu.ai/',
      icon: NavIcons.DocumentationIcon(),
      external: true,
    },
  ];

  return (
    <aside className="w-[295px]" data-testid="side-nav">
      <Link href={`/${locale}/apps`}>
        <Image src={Medium} alt="Logo" className="mx-3.5 mt-2.5" />
      </Link>
      <div className="mb-5 mt-1 space-y-[1px]">
        {sidebar.map((item) => {
          const isActive =
            item.id === 0
              ? pathname.startsWith(`/${locale}/apps`) ||
                pathname.startsWith(`/${locale}/components`)
              : pathname.startsWith(
                  item.id !== 4 ? `/${locale}${item.href}` : item.href
                );

          return (
            <React.Fragment key={item.id}>
              {item.tooltip ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link
                        target={item.external ? '_blank' : '_self'}
                        href={
                          item.id !== 4 ? `/${locale}${item.href}` : item.href
                        }
                        className={cn(
                          'mx-4 flex h-10 w-[200px] items-center gap-x-3 rounded-sm pl-4 text-body',
                          pathname.startsWith(
                            item.id !== 4 ? `/${locale}${item.href}` : item.href
                          )
                            ? 'bg-gradient-sky-dark'
                            : ''
                        )}
                      >
                        <span className="text-icon-fill">{item.icon}</span>
                        <p>{item.title}</p>
                        {item.wip && (
                          //TODO refactor arbitrary classes after design revamp
                          <span className="-ml-0.5 rounded bg-[#EFDA1F]/[.12] px-1.5 text-btn-label-sm text-[#EFDA1F]">
                            {messages?.General?.wip}
                          </span>
                        )}
                      </Link>
                    </TooltipTrigger>
                    <TooltipContent
                      side="bottom"
                      className="max-w-52 rounded border-transparent bg-[#07181E]"
                    >
                      {item.tooltip}
                      <TooltipArrow className="fill-[#07181E]" />
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <Link
                  // id is necessary for tour-guide
                  id={'guide' + item.id.toString()}
                  target={item.external ? '_blank' : '_self'}
                  href={item.id !== 4 ? `/${locale}${item.href}` : item.href}
                  className={cn(
                    'mx-4 flex h-10 w-[200px] items-center gap-x-3 rounded-[4px] pl-[18px] text-nav-text-default',
                    isActive ? 'bg-gradient-sky-dark-opacity' : ''
                  )}
                >
                  <span
                    className={cn(
                      'text-icon-fill',
                      isActive ? 'text-white' : ''
                    )}
                  >
                    {item.icon}
                  </span>
                  <p
                    className={cn(
                      'text-nav-link-active',
                      isActive ? 'text-nav-text-selected' : ''
                    )}
                  >
                    {item.title}
                  </p>
                  {item.wip && (
                    //TODO refactor arbitrary classes after design revamp
                    <span className="-ml-0.5 rounded bg-[#EFDA1F]/[.12] px-1.5 text-btn-label-sm text-[#EFDA1F]">
                      {messages?.General?.wip}
                    </span>
                  )}
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </aside>
  );
};
