/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/
'use client';

import { useState } from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import { formatDurationText } from '@/utils/formatDurationText';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { useTextComparison } from 'text-compare';
import { IPrompt, PromptsData } from 'types/prompt';

import { iconMap } from '@/lib/helper';
import { cn } from '@/lib/utils';

import { System } from '../icons';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';

export const ComparePrompts = ({
  data,
  rowHeader,
  findItem,
}: {
  data: PromptsData;
  rowHeader: any[];
  findItem: (
    rowHeader: string,
    prompt: IPrompt
  ) => string | number | JSX.Element;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { messages } = useLanguage();
  const langData = messages?.PromptsListing;

  const showSummary = data.length === 2;

  const text1 = showSummary
    ? (findItem(`${messages?.General?.output}`, data[0]) as string)
    : '';
  const text2 = showSummary
    ? (findItem(`${messages?.General?.output}`, data[1]) as string)
    : '';
  const options = {
    customColors: {
      commonColor: '#C1C9CF',
      removedColor: '#F26C74',
      addedColor: '#6CF2A2',
    },
  };
  const { comparisonResult, similarity } = useTextComparison(
    text1,
    text2,
    options
  );

  const allHeaders = showSummary
    ? [`${langData?.row_header.summary}`, ...rowHeader]
    : rowHeader;

  return (
    <div className="rounded-sm border border-line-secondary">
      <ScrollArea className="max-w-4xl">
        <div className="w-full">
          {allHeaders.map((header, rowIndex) => (
            <div key={header} className="flex bg-gradient-sky-dark">
              <div
                className={cn(
                  'sticky left-0 w-[200px] border-r border-r-line-secondary bg-[#093242] p-3 capitalize text-body-subtle',
                  rowIndex === 0 && 'rounded-tl-sm',
                  rowIndex === allHeaders.length - 1 && 'rounded-bl-sm',
                  rowIndex !== 0 && 'border-t border-t-line-secondary'
                )}
              >
                {header}
              </div>
              {header === langData?.row_header.summary ? (
                <div className={cn('flex-1 p-3', 'rounded-tr-lg')}>
                  <div className="flex items-center justify-between">
                    <span className="text-form-input-text text-white">
                      {langData?.text_similarity}:{' '}
                      <span className="font-semibold">
                        {similarity.toFixed(2)}%
                      </span>
                    </span>
                    <button
                      onClick={() => setIsExpanded(!isExpanded)}
                      className="flex items-center gap-1 text-form-input-text text-[#34BCAF]"
                    >
                      {isExpanded
                        ? langData?.hide_details
                        : langData?.view_details}
                      {isExpanded ? (
                        <ChevronDown className="h-4 w-4" />
                      ) : (
                        <ChevronRight className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                  {isExpanded && (
                    <div className="mt-[10px] flex flex-wrap gap-1 bg-[#08242F] p-2">
                      {comparisonResult}
                    </div>
                  )}

                  <p className="mt-2 flex items-center gap-1.5 text-form-input-text text-form-input-disabled">
                    {System.InfoIcon('size-4')}{' '}
                    {langData?.two_prompts_compare_info_msg}
                  </p>
                </div>
              ) : (
                data.map((prompt: IPrompt, colIndex: number) => (
                  <div
                    id={rowIndex.toString() + colIndex.toString()}
                    className={cn(
                      'flex min-w-[300px] flex-1 items-start gap-2 p-3 text-body-primary',
                      rowIndex === 0 &&
                        colIndex === data.length - 1 &&
                        'rounded-tr-sm',
                      rowIndex === allHeaders.length - 1 &&
                        colIndex === data.length - 1 &&
                        'rounded-br-sm',
                      colIndex === 0 && 'ml-px',
                      colIndex !== 0 && 'border-l border-l-line-secondary',
                      rowIndex !== 0 && 'border-t border-t-line-secondary'
                    )}
                    key={colIndex}
                  >
                    {header === messages?.General?.provider &&
                    findItem(header, prompt) ? (
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                        <Image
                          className="size-6"
                          src={
                            iconMap[prompt?.model_attributes?.provider_type] ??
                            iconMap['default']
                          }
                          alt={`${
                            prompt?.model_attributes?.provider_type ?? 'default'
                          } provider`}
                          width={100}
                          height={100}
                        />
                      </div>
                    ) : header === messages?.General?.service &&
                      findItem(header, prompt) ? (
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                        <Image
                          className="size-6"
                          src={
                            iconMap[prompt?.model_attributes?.service_type] ??
                            iconMap['default']
                          }
                          alt={`${
                            prompt?.model_attributes?.service_type ?? 'default'
                          } service`}
                          width={100}
                          height={100}
                        />
                      </div>
                    ) : (
                      ''
                    )}{' '}
                    {header === `${messages?.General?.duration}`
                      ? formatDurationText(findItem(header, prompt) as string)
                      : findItem(header, prompt)}
                  </div>
                ))
              )}
            </div>
          ))}
        </div>
        <ScrollBar
          orientation="horizontal"
          className="ml-[200px] bg-gray-300/20"
        />
      </ScrollArea>
    </div>
  );
};
