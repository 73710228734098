/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { LOGGEDIN_OKAHU_USER } from '@/services/request';
import { useUser } from '@auth0/nextjs-auth0/client';
import { ChevronDown, ChevronLeft } from 'lucide-react';

import { useStartTour } from '@/hooks/useStartTour';

import { Icons } from '../icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

export const TopNav = () => {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { setStartTour } = useStartTour();
  const pathname = usePathname();
  const { locale, messages } = useLanguage();

  const handleStartTour = () => {
    setStartTour(true);
    setOpen(false);
  };

  const handleLogout = () => {
    if (typeof window !== 'undefined' && user) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem(`${LOGGEDIN_OKAHU_USER}`);
      window.location.href = '/api/auth/logout';
    }
  };

  return (
    <div
      className="mb-2 flex items-center justify-between py-3"
      data-testid="top-nav"
    >
      {pathname === `/${locale}/apps` ? (
        <div className="flex items-center gap-28">
          <h2 className="pl-1 pt-2 text-h1 text-white">
            {messages?.General?.applications}
          </h2>
        </div>
      ) : pathname === `/${locale}/components` ? (
        <div className="flex items-center gap-28">
          <h2 className="pl-1 pt-2 text-h1 text-white">
            {messages?.General?.applications}
          </h2>
        </div>
      ) : pathname === `/${locale}/providers` ? (
        <h2 className="pl-2.5 pt-2 text-h1 text-white">
          {messages?.Sidebar?.providers}
        </h2>
      ) : pathname === `/${locale}/services` ? (
        <h2 className="pl-2.5 pt-2 text-h1 text-white">
          {messages?.General?.services}
        </h2>
      ) : pathname === `/${locale}/settings` ? (
        <h2 className="pl-2.5 pt-2 text-h1 text-white">
          {messages?.General?.settings}
        </h2>
      ) : pathname.startsWith(`/${locale}/services`) ? (
        <Link
          href={`/${locale}/services`}
          className="flex items-center text-body-subtle"
        >
          <ChevronLeft className="size-5" />
          <p className="text-nav-text-default">{messages?.General?.services}</p>
        </Link>
      ) : pathname.startsWith(`/${locale}/components`) ? (
        <Link
          href={`/${locale}/components`}
          className="flex items-center text-body-subtle"
        >
          <ChevronLeft className="size-5" />
          <p className="text-nav-text-default">
            {messages?.General?.components}
          </p>
        </Link>
      ) : pathname.startsWith(`/${locale}/providers`) ? (
        <Link
          href={`/${locale}/providers`}
          className="flex items-center text-body-subtle"
        >
          <ChevronLeft className="size-5" />
          <p className="text-nav-text-default">
            {messages?.Sidebar?.providers}
          </p>
        </Link>
      ) : (
        <Link
          href={`/${locale}/apps`}
          className="flex items-center text-body-subtle"
        >
          <ChevronLeft className="size-5" />
          <p className="text-nav-text-default">
            {messages?.General?.applications}
          </p>
        </Link>
      )}
      {user && (
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild data-testid="logout-drop-down">
            <Link
              href="#"
              className="flex items-center text-body-subtle"
              aria-label="Dropdown Menu"
            >
              <Image
                src={user?.picture || ''}
                alt={user?.name || ''}
                width={32}
                height={32}
                className="mr-2 rounded-full"
              />
              <p className="text-nav-text-default text-nav-link-active">
                {user?.name}
              </p>
              <ChevronDown className="ml-2 size-5 text-nav-link-active" />
            </Link>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="w-[180px] rounded bg-white shadow-[0px_0px_12px_0px_#00000080]">
            {pathname === `/${locale}/apps` && (
              <DropdownMenuLabel className="p-2 font-normal">
                <button
                  className="flex gap-x-2 text-kpi-label text-[#323130]"
                  onClick={handleStartTour}
                >
                  {Icons.WalkingIcon()}
                  {messages?.TopBar?.guided_tour}
                </button>
              </DropdownMenuLabel>
            )}
            <DropdownMenuLabel className="p-2 font-normal">
              <button
                onClick={handleLogout}
                className="flex w-full gap-x-2 text-kpi-label text-[#323130]"
              >
                {Icons.PowerIcon()}
                {messages?.General?.logout}
              </button>
            </DropdownMenuLabel>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};
