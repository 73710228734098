/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React from 'react';

import { useAppDetails } from '@/hooks/useAppDetails';
import { KPIItems } from '@/components/kpi';
import { DetailsPageLoading } from '@/components/loading';
import { ObservationCards } from '@/components/observations';

const InsightsPage = ({ appName }: { appName: string }) => {
  const { data, loading } = useAppDetails();

  if (loading || !data) {
    return <DetailsPageLoading />;
  }

  return (
    <>
      <KPIItems appName={appName} sla={data.sla} />
      <ObservationCards />
    </>
  );
};
export { InsightsPage };
