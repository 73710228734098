/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/
'use client';

import React from 'react';
import { useLanguage } from '@/providers/LanguageProvider';

import { DialogFooter } from '@/components/ui/dialog';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { CustomButton } from '../common';

interface PromptDetailsFooterProps {
  compare?: boolean;
  position?: number;
  dataLength?: number;
  messages?: {
    General?: {
      download?: string;
      share?: string;
      wip?: string;
    };
    Buttons?: {
      previous_prompt?: string;
      next_prompt?: string;
    };
  };
  onPrev?: () => void;
  onNext?: () => void;
}

const PromptDetailsFooter: React.FC<PromptDetailsFooterProps> = ({
  compare = false,
  position = 0,
  dataLength = 0,
  onPrev,
  onNext,
}) => {
  const { messages } = useLanguage();
  const langData = messages?.PromptsListing;
  return (
    <DialogFooter className="pt-5">
      {compare ? (
        <div className="space-x-4">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="inline-block cursor-not-allowed">
                  <CustomButton
                    disabled
                    customVariant="secondary"
                    customSize="regular"
                  >
                    {messages?.General?.download}
                    <span className="ml-1 rounded bg-[#EFDA1F] px-1.5 text-btn-label-sm text-[#07181E]">
                      {messages?.General?.wip}
                    </span>
                  </CustomButton>
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="bottom"
                className="max-w-52 rounded border-transparent bg-[#07181E]"
              >
                {langData?.download_wip_info_msg}{' '}
                <TooltipArrow className="fill-[#07181E]" />
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="inline-block cursor-not-allowed">
                  <CustomButton
                    disabled
                    customVariant="secondary"
                    customSize="regular"
                  >
                    {messages?.General?.share}
                    <span className="ml-1 rounded bg-[#EFDA1F] px-1.5 text-btn-label-sm text-[#07181E]">
                      {messages?.General?.wip}
                    </span>
                  </CustomButton>
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="bottom"
                className="max-w-52 rounded border-transparent bg-[#07181E]"
              >
                {langData?.share_wip_info_msg}{' '}
                <TooltipArrow className="fill-[#07181E]" />
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ) : (
        <div className="flex gap-3">
          <CustomButton
            customVariant="secondary"
            customSize="regular"
            disabled={position === 0}
            onClick={onPrev}
          >
            {messages?.Buttons.previous_prompt}
          </CustomButton>
          <CustomButton
            customVariant="secondary"
            customSize="regular"
            disabled={position === dataLength - 1}
            onClick={onNext}
          >
            {messages?.Buttons.next_prompt}
          </CustomButton>
        </div>
      )}
    </DialogFooter>
  );
};

export default PromptDetailsFooter;
