/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useEffect, useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { request } from '@/services/request';
import { toast } from 'sonner';

import { SpanDetails } from '../display-page/traces/span-details';
import { NotFoundMessage } from '../not-found';

export const DisplayGanttChart = ({
  trace_id,
  workflow_name,
}: {
  trace_id: string;
  workflow_name: string;
}) => {
  const { messages } = useLanguage();
  const langData = messages?.Traces.trace_span_details;
  const [state, setState] = useState({
    spanDetails: null,
    traceSpanLoading: false,
    error: false,
  });

  const fetchTraceSpanDetails = async () => {
    setState((prevState) => ({
      ...prevState,
      traceSpanLoading: true,
      error: false,
    }));
    try {
      const data = await request.get({
        endpoint: `workflows/${workflow_name}/traces/${trace_id}/spans`,
      });
      setState({
        spanDetails: data?.[0] || null,
        traceSpanLoading: false,
        error: false,
      });
    } catch (error) {
      console.error('Failed to fetch trace span details:', error);
      toast.error(`${langData?.no_trace_spans_error_msg}`);
      setState({ spanDetails: null, traceSpanLoading: false, error: true });
    }
  };

  useEffect(() => {
    fetchTraceSpanDetails();
  }, [trace_id, workflow_name]);

  if (state.error) {
    return (
      <NotFoundMessage
        title={langData?.no_trace_spans_found}
        outerDivClassName="h-[814px] my-0"
      />
    );
  }

  return (
    <div>
      <SpanDetails
        spanDetails={state.spanDetails}
        traceSpanLoading={state.traceSpanLoading}
        readOnly
      />
    </div>
  );
};
