/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import Close from 'public/shared-icons/Close.svg';
import { IPrompt } from 'types/prompt';

import { usePrompts } from '@/hooks/usePrompts';

import { CustomBadge, CustomButton } from '../common';
import { NotFoundMessage } from '../not-found';
import BasicLoader from '../spinner/basic-loader';
import { Dialog, DialogTrigger } from '../ui/dialog';
import { Skeleton } from '../ui/skeleton';
import { PromptDetailsModal } from './prompt-detail-modal';
import { PromptListAll } from './prompt-list';
import { PromptPagination } from './prompt-list-pagination';
import { SelectPromptGroup } from './select-group-prompt';

export const itemsPerPage = Number(
  process.env.NEXT_PUBLIC_PROMPTS_ITEMS_PER_PAGE
);
export const groupsPerPage = Number(
  process.env.NEXT_PUBLIC_PROMPTS_GROUPS_PER_PAGE
);

export const PromptsListing = () => {
  const [accordionValue, setAccordionValue] = useState<string>('item-0');
  const [selectedPrompt, setSelectedPrompt] = useState<IPrompt[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGrouping, setSelectedGrouping] = useState('');
  const { messages } = useLanguage();
  const langData = messages?.PromptsListing;

  const { state } = usePrompts();

  useEffect(() => {
    if (currentPage === 1) {
      setAccordionValue('item-0');
    } else {
      setAccordionValue('');
    }
  }, [currentPage]);

  const promptList =
    selectedGrouping.length > 0
      ? state?.promptData.reduce(
          (acc: Record<string, IPrompt[]>, prompt: any) => {
            const key =
              selectedGrouping === 'model_name'
                ? prompt[selectedGrouping]
                : prompt?.model_attributes?.provider_name;

            if (!key) {
              acc['Default'] = acc['Default'] || [];
              acc['Default'].push(prompt);
            } else {
              acc[key] = acc[key] || [];
              acc[key].push(prompt);
            }
            return acc;
          },
          {}
        )
      : state?.promptData;

  let sortPromptByGroupName = {};
  if (selectedGrouping.length > 1) {
    const sortByGroupKeys = Object.keys(promptList).sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
    );

    sortPromptByGroupName = Object.fromEntries(
      sortByGroupKeys.map((key) => [
        key,
        promptList[key].sort(
          (a: { start_time: string }, b: { start_time: string }) =>
            new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
        ),
      ])
    );
  }

  const getPage = (start: number) => {
    const entries = Object.entries(sortPromptByGroupName);
    const end = start + groupsPerPage;
    const pageEntries = entries.slice(start, end);
    return Object.fromEntries(pageEntries);
  };

  const totalPages =
    selectedGrouping.length > 0
      ? Math.ceil(Object.entries(promptList).length / groupsPerPage)
      : Math.ceil(promptList.length / itemsPerPage);

  const startIndex =
    selectedGrouping.length > 0
      ? (currentPage - 1) * groupsPerPage
      : (currentPage - 1) * itemsPerPage;

  const currentPrompts =
    selectedGrouping.length > 0
      ? getPage(startIndex)
      : promptList.slice(startIndex, startIndex + itemsPerPage);

  const hasPrompts = state?.promptData.length > 0;

  return (
    <div className="h-full p-4 px-6 pb-2 text-white">
      {state?.promptLoading ? (
        <div className="flex gap-1.5">
          <Skeleton className="animate-pulse-custom h-[23px] w-20 rounded-full bg-[#8C9EA1]" />
          <Skeleton className="animate-pulse-custom h-[23px] w-[23px] rounded-full bg-[#8C9EA1]" />
        </div>
      ) : (
        hasPrompts && (
          <h2 className="flex gap-1.5 text-h3">
            {messages?.General?.prompts}
            <CustomBadge customSize="lg">
              {state?.promptData?.length}
            </CustomBadge>
          </h2>
        )
      )}
      <div className="flex flex-col justify-between rounded-md pb-3 pt-4">
        <div>
          {!state?.promptLoading && hasPrompts && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <Dialog>
                  <DialogTrigger asChild>
                    <CustomButton
                      customVariant="secondary"
                      customSize="regular"
                      disabled={selectedPrompt.length < 2}
                    >
                      {langData?.compare}
                    </CustomButton>
                  </DialogTrigger>
                  <PromptDetailsModal data={selectedPrompt} compare />
                </Dialog>

                {selectedPrompt.length > 0 && (
                  <div className="flex items-center">
                    <p className="border-r border-[#0A343C] pr-3 text-body text-body-subtle">
                      {selectedPrompt.length} {langData?.item}
                      {selectedPrompt.length > 1 && messages?.General?.s}{' '}
                      {langData?.selected}
                    </p>

                    <CustomButton
                      variant="link"
                      className="flex font-normal text-link-active hover:no-underline"
                      onClick={() => setSelectedPrompt([])}
                    >
                      <div className="mr-1 h-3 w-3 p-[1px]">
                        <Image
                          src={Close}
                          width={100}
                          height={100}
                          alt="close icon"
                        />
                      </div>
                      <span>{langData?.clear_selection}</span>
                    </CustomButton>
                  </div>
                )}
              </div>
              <div className="flex items-center gap-2">
                <p className="text-sm">{langData?.group_by}</p>
                <SelectPromptGroup
                  selectedGrouping={selectedGrouping}
                  setSelectedGrouping={setSelectedGrouping}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
          {state?.promptLoading ? (
            <div className="flex h-[814px] flex-1 items-center justify-center">
              <BasicLoader />
            </div>
          ) : hasPrompts ? (
            <PromptListAll
              accordionValue={accordionValue}
              setAccordionValue={setAccordionValue}
              currentPrompts={currentPrompts}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              selectedGrouping={selectedGrouping}
              startIndex={startIndex}
            />
          ) : (
            <NotFoundMessage
              title={langData?.no_prompts_found}
              outerDivClassName="h-[814px] my-0"
            />
          )}
        </div>

        {!state?.promptLoading && hasPrompts && (
          <PromptPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            promptList={promptList}
          />
        )}
      </div>
    </div>
  );
};
