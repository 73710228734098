/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import React from 'react';
import { IPrompt } from 'types/prompt';

const parseInput = (input: any) => {
  if (!input) return '';

  try {
    if (typeof input === 'string') {
      const parsed = new Function(`return ${input}`)();
      return parsed;
    }
    return input;
  } catch {
    return typeof input === 'string' ? input : '';
  }
};

const MultiplePromptDisplay = ({
  inputData,
  isDetails,
}: {
  inputData: IPrompt;
  isDetails?: boolean;
}) => {
  const renderContent = (data: IPrompt) => {
    if (!data?.input) return null;

    if (Array.isArray(data.input.input)) {
      const inputArray = data.input.input;
      if (!inputArray.length) return null;

      const lastElement = parseInput(inputArray[inputArray.length - 1]);
      const lastQuestion =
        typeof lastElement === 'string'
          ? lastElement
          : lastElement?.[Object.keys(lastElement)[0]] || '';

      const exchanges = inputArray.map((item) => {
        const parsed = parseInput(item);
        return typeof parsed === 'string'
          ? { content: parsed }
          : {
              role: Object.keys(parsed)[0],
              content: parsed[Object.keys(parsed)[0]],
            };
      });

      return (
        <div className={`flex flex-col ${!isDetails && 'px-2 pb-3'}`}>
          <p className="mx-2 mb-3 mt-[2px] text-form-input-text text-body-primary">
            {lastQuestion}
          </p>

          <div className="space-y-1 rounded bg-white/[8%] p-2">
            {exchanges.map((exchange, index) => (
              <div key={index}>
                {exchange.role && (
                  <p className="text-body-sm capitalize text-form-input-disabled">
                    {exchange.role}
                  </p>
                )}
                <p className="mt-[2px] text-form-input-text text-body-primary">
                  {exchange.content}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <p className={`${!isDetails && 'mx-4'} mt-1 text-body-primary`}>
        {data?.input?.question}
      </p>
    );
  };

  return renderContent(inputData);
};

export { MultiplePromptDisplay };
