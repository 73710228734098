/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React, { ReactNode, useCallback, useContext, useState } from 'react';
import { KPIContext } from '@/context';
import { request } from '@/services/request';
import { DateRange } from 'react-day-picker';
import { KPI } from 'types/kpi-data';

export const KPIProvider: React.FC<{
  children: ReactNode;
  workflowName?: string;
}> = ({ children, workflowName }) => {
  const [data, setData] = useState<{ [key: string]: KPI }>();
  const [loading, setLoading] = useState(false);
  const [currentAppName, setCurrentAppName] = useState<string>();
  const [fetchRange, setFetchRange] = useState<DateRange>();

  const fetchKPI = useCallback(async (appName: string, range?: any) => {
    try {
      setLoading(true);
      setCurrentAppName(appName);

      // Use provided range or generate default

      const from = range?.from;
      const to = range?.to;

      const queryParams = {
        start_time: from.toISOString(),
        end_time: to.toISOString(),
      };
      const endpoint = workflowName
        ? `insights/workflows/${workflowName}`
        : `insights/apps/${appName}`;

      const fetchedData = await request.get({
        endpoint,
        queryParams,
      });
      setData(fetchedData[0]);
      setFetchRange(range);
    } catch (error) {
      console.error('Error fetching KPI data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const refreshKPIData = useCallback(() => {
    if (currentAppName && fetchRange) {
      fetchKPI(currentAppName, fetchRange);
    } else if (workflowName) {
      fetchKPI(workflowName, fetchRange);
    }
  }, [currentAppName, fetchRange, fetchKPI, workflowName]);

  return (
    <KPIContext.Provider
      value={{
        data,
        loading,
        fetchKPI,
        refreshKPIData,
      }}
    >
      {children}
    </KPIContext.Provider>
  );
};

// Custom hook to use the KPI context
export const useKPIContext = () => {
  const context = useContext(KPIContext);
  return context;
};
