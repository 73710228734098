/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { System } from '../icons';

type CardsPropsType = {
  id?: number;
  name: string;
  title: string;
  description: string;
  image: string;
  date: string;
};

const data: CardsPropsType[] = [
  {
    id: 0,
    name: 'Triton',
    title: 'GPU Saturation in US West',
    description:
      'The GPU usage for Triton service increase by 80% during last 2 hours.',
    image: 'nvidia',
    date: 'May 31, 2024, 14:32',
  },
  {
    id: 1,
    name: 'Flan5',
    title: 'Model latency increased by 250%',
    description:
      'The average response time for Flat5 model has increased by 250% in for last 20 inferences.',
    image: 'langchain',
    date: 'July 31, 2010, 18:46',
  },
];

export const ObservationCards = () => {
  const { messages } = useLanguage();
  const langData = messages?.ApplicationDetailPage;
  return (
    <div className="mt-10 pb-10">
      <div className="mb-0.5 flex items-center gap-2.5 pb-3">
        <h3 className="text-h2 text-body-primary">{langData?.observations}</h3>
        {System.InfoIcon('size-4 text-icon-fill')}
      </div>

      {/* cards */}
      <div className="flex gap-x-[30.83px]">
        {data.map(({ id, name, title, description, image, date }) => (
          <Card
            key={id}
            name={name}
            title={title}
            description={description}
            image={image}
            date={date}
          />
        ))}
      </div>
    </div>
  );
};

const Card = ({ name, title, description, image, date }: CardsPropsType) => {
  const { messages } = useLanguage();
  const langData = messages?.ApplicationDetailPage;
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="relative w-full cursor-not-allowed overflow-hidden rounded-md border border-line-secondary bg-canvas-background-primary p-4">
            <div className="text-body-primary">
              <p className="text-body-sm text-body-subtle">{date}</p>
              <div className="flex items-center gap-2.5 pt-2">
                <span className="flex size-8 items-center justify-center rounded-full bg-white">
                  <Image
                    className="size-5"
                    src={`/logos/provider/${image}.svg`}
                    alt="Langchain Logo"
                    width={100}
                    height={100}
                  />
                </span>
                <p className="text-h4 font-medium">{name}</p>
              </div>
              <p className="my-2.5 text-h3 font-medium">{title}</p>
              <p className="text-body text-form-input-disabled">
                {description}
              </p>
            </div>
            <div>
              <div className="absolute -right-[3.6rem] top-5 w-fit rotate-45 bg-[#EFDA1F]/[.12] px-[62px] py-2.5 text-center text-body-sm text-[#EFDA1F]">
                {messages?.General?.coming_soon}
              </div>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          className="max-w-52 rounded border-transparent bg-[#07181E]"
        >
          {langData?.get_more_information_on_every_component}
          <TooltipArrow className="fill-[#07181E]" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
