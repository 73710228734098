/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React, { useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';

import { useAppDetails } from '@/hooks/useAppDetails';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TitleBar } from '@/components/nav';
import { DisplayNotFound } from '@/components/not-found';

import { Skeleton } from '../ui/skeleton';

interface AppDetailsHeaderProps {
  children: React.ReactNode;
  appName: string;
}

const AppDetailsHeader = ({ children, appName }: AppDetailsHeaderProps) => {
  interface LanguageTabs {
    [key: string]: string;
  }

  const { data, error, loading } = useAppDetails();
  const router = useRouter();
  const pathname = usePathname();
  const { messages } = useLanguage();
  const langData: LanguageTabs = messages?.Tabs || {};
  const tabs = Object.keys(langData);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    const newActiveTab =
      tabs.find((tab) => pathname?.includes(`/${tab}`)) || tabs[0];
    setActiveTab(newActiveTab);
  }, [pathname, tabs]);

  const handleTabChange = (value: string) => {
    let url = `/apps/${appName}${value !== tabs[0] ? `/${value}` : ''}`;

    // Add query param for Insights tab
    if (value === 'insights') {
      const queryParam = data?.is_playground ? 'days=all' : 'days=7';
      url += `?${queryParam}`;
    }

    router.push(url);
  };

  if (loading || !data)
    return (
      <>
        <div className="mb-10 space-y-1">
          <Skeleton className="animate-pulse-custom h-6 w-[350px] rounded-full bg-[#8C9EA1]" />
          <Skeleton className="animate-pulse-custom h-5 w-44 rounded-full bg-[#8C9EA1]" />
        </div>
        <Skeleton className="animate-pulse-custom h-8 w-64 rounded-full bg-[#8C9EA1]" />
      </>
    );
  if (error) return <DisplayNotFound />;

  return (
    <>
      <TitleBar data={data} />
      <Tabs
        value={activeTab}
        className="w-full"
        onValueChange={handleTabChange}
      >
        <TabsList className="h-[48px] w-full justify-start space-x-8 rounded-none border-b border-[#40565A] bg-transparent p-0">
          {tabs.map((tab) => (
            <TabsTrigger
              key={tab}
              value={tab}
              className="px-0 text-base font-medium text-link-active decoration-[3px] underline-offset-[16px] hover:underline data-[state=active]:bg-transparent data-[state=active]:underline"
            >
              {langData[tab]}
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent value={activeTab} className="mt-0">
          {children}
        </TabsContent>
      </Tabs>
    </>
  );
};

export { AppDetailsHeader };
